<template>
  <div class="home">
    <img src="/img/logo.svg" alt="Logo Instituto Burmann & Bastos" class="logo">
    <transition name="page" mode="out-in">
      <div class="d-p">
        <div class="two-cols">
          <router-link to="mulheres" class="step-link">
            <img src="/img/woman.jpg" alt="Mulher">
            Para Mulheres
          </router-link>
          <router-link to="homens" class="step-link">
            <img src="/img/man.jpg" alt="Homem">
            Para Homens
          </router-link>
          <router-link to="tecnologias" class="step-link">
            <img src="/img/tech2.jpeg" alt="Tecnologia">
            Tecnologias
          </router-link>
          <router-link to="nossos-profissionais" class="step-link">
            <img src="/img/ibb-fertiana.jpg" alt="Equipe Instituto Burmann & Bastos">
            Nossos Profissionais
          </router-link>
        </div>
        <router-link to="cardapio" class="menu-btn">Abrir Cardápio de Bebidas e Lanches</router-link>
      </div>
    </transition>
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {

  },
  data: () => {
    return {
      loading: true,
      menu: ''
    }
  },
  async created() {}
}
</script>

<style scoped lang="scss">
  .home {
    padding-top: 25px;
    text-align: center;
    position: relative;

    .logo {
      max-width: 90%;
      width: 150px;
      height: auto;
      position: relative;
    }

    .menu-btn {
      display: inline-block;
      margin-top: 50px;
      background-color: rgba(255, 255, 255, 0.8);
      color: $main-color;
      border: none;
      border-radius: 5px;
      padding: 10px 30px;
      font-size: 1rem;
      text-transform: uppercase;
      font-weight: bold;
      transition: all 0.3s;

      &:hover {
        background-color: $main-color;
        color: #FFFFFF;
      }
    }
  }
</style>
